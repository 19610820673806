@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");


/*******************Agenda de servicios*************************/

.item-1-schedule {
    display: grid;
    padding: 10px;
    grid-template-columns: 75px 1fr 90px;
    grid-row-gap: 10px;
    grid-column-gap: 7px;
    justify-items: left;
    text-align: center;
    align-items: center;
    border: 5px solid #ffffff;
    -webkit-box-shadow: 0 0 2px #7e7e7e, 0 0 3px #7e7e7e;
    box-shadow: 0 0 2px #7e7e7e, 0 0 3px #7e7e7e;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.sub-item-1-schedule {
    width: 100%;
    display: grid;
    justify-items: left;
}

.sub-item-1-schedule img {
    width: 70px;
    height: 70px;
    border: 2px solid #003474;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/********detalle de mis servicios**********/

.item-1-modal-detail {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 7px;
    justify-items: left;
    text-align: center;
    align-items: center;
    border-radius: 10px;
}

.sub-item-1-modal-detail {
    display: grid;
    justify-items: left;
    margin-bottom: 10px;
    margin-top: 10px;

}

.sub-item-1-modal-detail img {
    justify-items: left;
    width: 200px;
}

.sub-item-2-modal-detail {
    display: grid;
    justify-items: left;
    margin-top: 10px;
    margin-bottom: 10px;

}

.sub-item-2-modal-detail button {
    width: 100%;
    margin-bottom: 10px;

}

/********Ver producto**********/

.item-1-modal-product {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 7px;
    justify-items: left;
    text-align: center;
    align-items: center;
    border-radius: 10px;
}

.sub-item-1-modal-product1 {
    display: flex;
    overflow-x: scroll;
    max-width: 100%;

}

.img-product-1 {
    display: block;
    text-align: left;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #b2b2b2;
    -webkit-border-radius: 10px;
    border-radius: 8px;
    background: #ffffff;
}

.img-product-1 img {
    width: 200px;
    max-width: 200px;
}

.sub-item-2-modal-product {
    width: 100%;
    display: grid;
    justify-items: left;
    margin-top: 10px;

}

.sub-item-2-modal-product button {
    width: 150px;
    margin-bottom: 10px;

}

.sub-item-3-modal-product {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr ;
    justify-items: left;

}

/*Estados de servicios*/

.item-1-modal-status {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 7px;
    justify-items: left;
    text-align: center;
    align-items: center;
    border-radius: 10px;
}

.sub-item-1-modal-status {
    justify-items: left;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    -webkit-box-shadow: 0 0 2px #7e7e7e, 0 0 3px #7e7e7e;
    box-shadow: 0 0 2px #7e7e7e, 0 0 3px #7e7e7e;
    -webkit-border-radius: 10px;
    border-radius: 10px;

}

.sub-item-2-modal-status {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px;
    justify-items: left;
}

.sub-item-1-modal-status img {
    justify-items: left;
    width: 70px;
}

.sub-item-3-modal-status {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3px;
    justify-items: center;
}

.sub-item-order-modal-status {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3px;
    justify-items: center;
}

.sub-item-4-modal-status {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 51px;
    column-gap: 3px;
    justify-items: center;
}


/********responsive design ipad***********/

@media screen and (min-width: 760px) {

    .sub-item-1-modal-product1 {
        display: flex;
        overflow-x: scroll;
        width: 26vw;

    }
}

/********responsive design pc**************/

@media screen and (min-width: 1030px) {


}
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");

.styles-container {
    float: left;
    margin-top: 43px;
    width: 100%;
    padding: 20px;
    z-index: 98;
}

.styles-container2 {
    float: left;
    margin-top: 0;
    width: 100%;
    padding: 0 20px 20px 20px;
    z-index: 98;
}

.conteiner-page-sector1 {
    background: #fff;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 25px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    margin-bottom: 15px;
}

.conteiner-page-sector1 h1 {
    color: black;
    font-size: 16px;
}

.conteiner-page-sector1 h2 {
    color: #59565e;
    font-size: 14px;
}

.styles-title-page-h1 h1 {
    display: flex;
    text-align: left;
    font-size: 18px;
    margin-bottom: 20px;
}

.styles-title-page-h1 button {
    display: flex;
    justify-content: left;
}

.conteiner-page-sector2 {
    width: 90%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background: #fff;
    margin-bottom: 20px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 30px;
    -webkit-box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    flex-wrap: wrap;
}

.conteiner-page-sector2 h1 {
    color: #261820;
    font-size: 16px;
}

.conteiner-page-sector2 h2 {
    color: #59565e;
    font-size: 14px;
}

.formulario2 {
    display: grid;
    grid-template-columns: 1fr;
}

.item-formulario2 {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-items: left;
}

.formulario2 h3 {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 400;
    min-width: 170px;
    color: #576f89;
    max-width: 100px;
}

.formulario2 h2 {
    margin-top: 17px;
    font-size: 15px;
    min-width: 170px;
    max-width: 100px;
}

.conteiner-page-sector1 h2 {
    font-size: 14px;
    width: 200px;
    min-width: 200px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.conteiner-page-sector1 h3 {
    font-size: 15px;
    font-weight: 100;
    width: 200px;
    min-width: 200px;
    margin-top: 10px;
    color: #233648;
    margin-bottom: 15px;
}

.conteiner-page-sector2 h2 {
    font-size: 15px;
    font-weight: 600;
    color: #233648;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 250px;
    min-width: 200px;
}

.contenedor-btn-sobre-imagen img {
    width: 300px;
    height: auto;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.contenedor-btn-sobre-imagen a {
    bottom: 5px;
    width: 300px;
    position: absolute;
}

/********responsive design ipad***********/
@media screen and (min-width: 760px) {
    .item-formulario2 {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 190px 200px;
        grid-template-rows: 40px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        justify-items: left;
    }
}

@media screen and (min-width: 1030px) {
    .conteiner-page-sector1 {
        margin-left: 320px;
    }

    .formulario2, .formulario {
        display: block;
    }

    .styles-title-page h1 {
        margin-top: 10px;
        font-size: 18px;
        color: black;
    }

    .conteiner-page-sector1 h3 {
        font-size: 15px;
        font-weight: 100;
        width: 200px;
        min-width: 200px;
        margin-top: 10px;
        color: #233648;
        margin-bottom: 15px;
    }

    .formulario2 h3 {
        margin-top: 18px;
        font-size: 14px;
        font-weight: 400;
        min-width: 170px;
        color: #576f89;
    }
}
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");


table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
}

thead {
    background-color: #13296a;
    color: white;
}

tr:hover td {
    background-color: #fafafa;
}


.tex-align-h1 {
    width: 100%;
    text-align: left;
}

/*Diseño de de tablas*/
.Conteiner-table {
    width: 100%;
    height: auto;
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}

/************************/

.Conteiner-table::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    /*display: none;*/
}

.Conteiner-table::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.Conteiner-table::-webkit-scrollbar-thumb:hover {
    background: #13296a;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
.Conteiner-table::-webkit-scrollbar-track {
    background: #e1e1e1;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.table-config{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    align-items: center;
}
.table-config-width{
    min-width: 343px;
}

.container-config {
    width: 130px;
}

 tbody::-webkit-scrollbar,.table-installation tbody::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    /*display: none;*/
}

tbody::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
tbody::-webkit-scrollbar-thumb:hover {
    background: #13296a;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
tbody::-webkit-scrollbar-track {
    background: #e1e1e1;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/************************/

.table-post tr, td, th {
    border: 1px solid #eeedec;
    font-size: 13px;
    font-weight: 100;
    padding: 10px;
}

.table-post tr {
    color: red;
}

/*table diseño*/

.table-design tr, .table-design td, .table-design th {
    width: 190px;
}

.table-design td {
    color: #787878;
}

.table-design tbody {
    width: 4761px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-design {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table instalación*/

.table-installation tr, .table-installation td, .table-installation th {
    width: 221px;
}

.table-installation td {
    color: #787878;
}

.table-installation tbody {
    width: 7294px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-installation {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

.div-design-config {
    width: 166px;
}

/******************************************************/
/*table mantenimiento*/

.table-maintenance tr, .table-maintenance td, .table-maintenance th {
    width: 220px;
}

.table-maintenance td {
    color: #787878;
}

.table-maintenance tbody {
    width: 7261px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-maintenance {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table provedores*/

.table-providers tr, .table-providers td, .table-providers th {
    width: 250px;
}

.table-providers td {
    color: #787878;
}

.table-providers tbody {
    width: 3509px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-providers {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

.imgLogos {
    width: 100%;
}

.table-supplies tr, .table-supplies td, .table-supplies th {
    width: 219px;
}

.table-supplies td {
    color: #787878;
}

.table-supplies tbody {
    width: 3734px;
    height: auto;
    max-height: 600px;
    display: block;
    overflow: auto;
}

.table-supplies {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

.style-button-table button {
    margin-bottom: 4px;
}

.table-orders tr, .table-orders td, .table-orders th {
    width: 220px;
}

.table-orders td {
    color: #787878;
}

.table-orders tbody {
    width: 2210px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-orders {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table emergency*/

.table-emergency tr, .table-emergency td, .table-emergency th {
    width: 280px;
}

.table-emergency td {
    color: #787878;
}

.table-emergency tbody {
    width: 6730px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-emergency {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table cliente*/

.table-client tr, .table-client td, .table-client th {
    width: 280px;
}

.table-client td {
    color: #787878;
}

.table-client tbody {
    width: 3089px;
    height: auto;
    max-height: 350px;
    display: block;
    overflow: auto;
}

.table-client {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table gerente lider*/

.table-leader-manager tr, .table-leader-manager td, .table-leader-manager th {
    width: 280px;
}
.table-leader-manager td {
    color: #787878;
}

.table-leader-manager tbody {
    width: 5050.999756px;
    height: auto;
    max-height: 350px;
    display: block;
    overflow: auto;
}

.table-leader-manager {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table provedores*/

.table-providers2 tr, .table-providers2 td, .table-providers2 {
    width: 220px;
}

.table-providers2 td {
    color: #787878;
}

.table-providers2 tbody {
    width: 2430px;
    height: auto;
    max-height: 350px;
    display: block;
    overflow: auto;
}

.table-providers2 {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}

/******************************************************/
/*table configurar servicio diseño*/

.table-values tr, .table-values td, .table-values th {
    width: 345px;
}

.table-values td {
    color: #787878;
}

.table-values tbody {
    width: 690px;
    height: auto;
    max-height: 350px;
    display: block;
    overflow: auto;
}

.table-values {
    text-align: left;
    table-layout: fixed;
    width: 40%;
    border-collapse: collapse;
}

/******************************************************/

/*table configurar servicio diseño*/

.table-brands tr, .table-brands td, .table-brands th {
    width: 250px;
}

.table-brands td {
    color: #787878;
}

.table-brands tbody {
    width: 760px;
    height: auto;
    max-height: 350px;
    display: block;
    overflow: auto;
}

.table-brands {
    table-layout: fixed;
    width: 40%;
    border-collapse: collapse;
}

.style-img-brand img {
    width: 100px;
    height: auto;
}

.style-btn-brand button {
    margin-left: 5px;
}

/******************************************************/


.select-table {
    margin-top: 10px;
    min-width: 40px;
    width: 100%;
    background: url(../assets/icons/iconos-bajar-negro-selec.png) no-repeat 90% center;
}

.on-off {
    margin-top: 14px;
    min-width: 20px;
    width: 100%;
    background: url(../assets/icons/iconos-bajar-negro-selec.png) no-repeat 80% center;
}

@media screen and (min-width: 760px) {
    .Btn-general {
        justify-items: right;
    }

    .Btn-general-filter {
        grid-template-columns: 1fr;
    }

    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .container-add-direction2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .btn-general-only {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .div-btn-add2 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 100%;
    }

    .Btn-general-config {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;
    }

    .Btn-buscar {
        width: 200px;
    }

    .div-btn-filter {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
    }

    .div-btn-filter-config {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .Btn-general-filter {
        grid-template-columns: 1fr 1fr;
    }

    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
}


.state_Solicitado {
    color: green;
}

.state_En_Proceso {
    color: blue;
}

.state_Terminado {
    color: red;
}

/*terminan tablas*/

@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");


.hidden {
    display: none;
}

.modal-error {
    background: #e43e51;
    margin-bottom: 10px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 3px;
}

.incorrect {
    color: #FFFFFF;
    font-size: 15px;
}

.separate {
    margin-top: 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.97)
}

.separate h2 {
    font-size: 15px;
    color: #fff;
    font-weight: 100;
}

.separate a {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}

.container-register {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    /*background: url("../assets/img/background.jpg") no-repeat;*/
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    height: 100vh;
}

.container-register-img {
    background: none;
}

.container-info {
    display: none;
}

.input-group input{
    /*background: transparent;*/
    border: 1px solid #000000;
    color: rgba(0, 0, 0, 0.98);
}
.input-group ::-webkit-input-placeholder { color: rgba(98, 98, 98, 0.96); }

.input-group :-moz-placeholder { /* Firefox 18- */ color: rgba(98, 98, 98, 0.96); }

.input-group ::-moz-placeholder { /* Firefox 19+ */ color: rgba(98, 98, 98, 0.96); }

.input-group :-ms-input-placeholder { color:rgba(98, 98, 98, 0.96); }

.title {
    text-decoration: none;
}

.title-form-h1 {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.97);
}

.title-form-h2 {
    color: #3c424f;
    font-weight: 100;
    font-size: 17px;
}

.register {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    width: 100%;
    height: 39px;
    border: 1px solid #fafafa;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    background: #0c1a35;
    /*background-image: -webkit-linear-gradient(160deg, #0083aa, #19b3f2);*/
    /*background-image: -o-linear-gradient(160deg, #0083aa, #19b3f2);*/
    /*background-image: linear-gradient(290deg, #0083aa,#19b3f2);*/
}

.register:hover {
    background: black;
    color: #FFFFFF;
}

#form-register {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 55%;
}
#form-register p {
  color: rgba(98, 98, 98, 0.96);
}

#form-register img {
    width: 110px;
}

.hiden {
    display: none;
}

@media screen and (min-width: 300px) {

    .container-register-img {
        /*background: url("../assets/img/background.jpg") no-repeat;*/
        background: none;
    }

    .input-group {
        width: 260px;
        height: 45px;
        margin-bottom: 1em;
    }
}

@media screen and (min-width: 850px) {
    .hidden2 {
        display: none;
    }

    .hiden {
        display: block;
    }

    #form-register {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 22%;
    }

    .hiden {
        display: block;
    }

    .hiden2 {
        display: none;
    }

    .container-register {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100vh;
        background: #fff;
    }

    .title-form-h1 {
        font-size: 25px;
        color: #59565e;
    }

    .separate h2 {
        font-size: 15px;
        color: #59565e;
        font-weight: 100;
    }

    .separate a {
        font-size: 15px;
        color: #59565e;
        font-weight: 600;
        text-decoration: none;
    }

    .container-info {
        display: initial;
        background: url("../assets/img/background.jpg") no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;
        color: white;
    }

    .container-info .title {
        font-size: 4em;
        font-weight: 600;
        color: #FFFFFF;
    }

    .info {
        display: grid;
        justify-items: left;
        margin-top: 22%;
        padding: 2em 0 0 4em;
        background-color: transparent;
    }

    .title-register {
        font-size: 2em;
        font-weight: 700;
        letter-spacing: -0.4px;
    }

    .subtitle {
        font-weight: 300;
        font-size: 1.4em;
        letter-spacing: -0.4px;
        margin-bottom: 2.4em;
    }

    .step {
        font-weight: 600;
        font-size: 0.9em;
        margin-bottom: 10px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .step h2 {
        margin-left: 15px;
        font-weight: 100;
    }

    .circle {
        width: 38px;
        padding: 15px;
        background-color: white;
        -webkit-border-radius: 10%;
        border-radius: 10%;
        margin-right: 0.2em;
        color: rgba(0,0,0,.97);
    }

    .input-group {
        width: 300px;
    }

    span {
        color: black;
    }


    .input-group ::-webkit-input-placeholder { color: #707171; }

    .input-group :-moz-placeholder { /* Firefox 18- */ color: #707171; }

    .input-group ::-moz-placeholder { /* Firefox 19+ */ color: #707171; }

    .input-group :-ms-input-placeholder { color: #707171; }

    .input-group input{
        background: transparent;
        border: 1px solid #a7a8a8;
        color: #333333;
    }
    #form-register p {
        color: #707171;
    }


}

.container-notification {
    margin-top: 2em;
}

.correct {
    color: green;
}

.incorrect, .correct {
    margin-left: 28%;
}

.required {
    border-color: red !important;
}

